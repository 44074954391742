// set up our selectors.  we need to target our hamburger click button and our menu
var hamburger = document.querySelector( ".toggleMenu" )
var menu = document.querySelector( "#navigation" )

// when the user clicks the hamburger we want to run the toggleMenu() function
hamburger.addEventListener( "click", toggleMenu );

// this function checks to seeif the menu contains the class 'open'.  If it doesnt, we need to apply it or remove it.
function toggleMenu() {
	if( menu.classList.contains( "open" ) === true ){
		//hide menu
		menu.classList.remove( "open" );
	}
	else{
		// show menu
		menu.classList.add( "open" );
	}

	// THIS VERSION CHECKS FOR AN INLINE STYLE OF OPEN/FLEX (BLOCK)
	/*
	if(menu.style.display=="none"){
		menu.style.display="flex";
	}
	else {
		menu.style.display="none";
	}*/
}

// modal handlers
// 1. get a list of all of the modal toggle buttons on page
var modal = document.querySelector( '#modal' )
var closeButton = document.querySelector( '#close' )
var bodyElement = document.querySelector( 'body' )
var modalContainer = document.querySelector( '.modal-container' )
var modalToggles = document.querySelectorAll( '.modal-toggle' );
var modalBios = document.querySelectorAll( '.modal-bio' );
console.log( 'all buttons:', modalToggles );
console.log( 'modal:', modal );

closeButton.addEventListener( 'click', closeModal );

// 2. setup a click event on all those buttons. and run a function on click
modalToggles.forEach( function(modal) {
	modal.addEventListener( 'click', handleModalPopup );
});

// close modal on escape key
document.addEventListener( 'keydown', function(event) {
	if( event.key == 'Escape' || event.keyCode == 27 ) {
		closeModal();
	}
});

function handleModalPopup(e) {
	e.preventDefault(); // prevents site from jumping
	closeBios(); // close any open bios

	// 1. before the modal displays, we want to set the background overlay to the height of the whole web page
	const heightOfWebSite = bodyElement.scrollHeight;
	modal.style.height = `${heightOfWebSite}px`;
	// 2. get where the user is scrolled down to. and then position the popup there.
	let scrollTop = 0;
	if (document.body.scrollTop){
		scrollTop = document.body.scrollTop;
	}
	else if (document.documentElement.scrollTop){
		scrollTop = document.documentElement.scrollTop;
	}

	// apply the top position to the container
	modalContainer.style.top = `${scrollTop}px`;

	// 3.  Find out which bio we want to see.
 	var bioToOpen = this.dataset.bio;
	//4. open the modal popup
	modal.classList.add( 'open' );
	// 5. open up that specific bio
	console.log('Open up: ', '#bio-'+ bioToOpen );
	var bio = document.querySelector( `#bio-${bioToOpen}` );
	bio.classList.add( 'open' );

}

function closeModal() {
	modal.classList.remove( 'open' );
	// hide all the bios that are open
	closeBios();
}

function closeBios() {
	// loop and remove open class for all bios.
	modalBios.forEach( function(bio) {
		bio.classList.remove( 'open' );
	});
}

// end modal

google.maps.event.addDomListener(window, 'load', init);

    function init() {
        // Basic options for a simple Google Map
        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
        var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 11,
            streetViewControl: false,
            mapTypeControl: false,
            scrollwheel: false,
            // The latitude and longitude to center the map (always required)
            center: new google.maps.LatLng(43.155714, -79.084340),
            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            styles: [{
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#e9e9e9"
                }, {
                    "lightness": 17
                }]
            }, {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#f5f5f5"
                }, {
                    "lightness": 20
                }]
            }, {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#ffffff"
                }, {
                    "lightness": 17
                }]
            }, {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [{
                    "color": "#ffffff"
                }, {
                    "lightness": 29
                }, {
                    "weight": 0.2
                }]
            }, {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#ffffff"
                }, {
                    "lightness": 18
                }]
            }, {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#ffffff"
                }, {
                    "lightness": 16
                }]
            }, {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#f5f5f5"
                }, {
                    "lightness": 21
                }]
            }, {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#dedede"
                }, {
                    "lightness": 21
                }]
            }, {
                "elementType": "labels.text.stroke",
                "stylers": [{
                    "visibility": "on"
                }, {
                    "color": "#ffffff"
                }, {
                    "lightness": 16
                }]
            }, {
                "elementType": "labels.text.fill",
                "stylers": [{
                    "saturation": 36
                }, {
                    "color": "#333333"
                }, {
                    "lightness": 40
                }]
            }, {
                "elementType": "labels.icon",
                "stylers": [{
                    "visibility": "off"
                }]
            }, {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [{
                    "color": "#f2f2f2"
                }, {
                    "lightness": 19
                }]
            }, {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#fefefe"
                }, {
                    "lightness": 20
                }]
            }, {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [{
                    "color": "#fefefe"
                }, {
                    "lightness": 17
                }, {
                    "weight": 1.2
                }]
            }]
        };
        var mapElement = document.getElementById('map');
        var map = new google.maps.Map(mapElement, mapOptions);
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(43.082765, -79.083138),
            map: map
        });
        var info = new InfoBubble({
            content: '<h4 style="margin:0 auto; max-width:280px">Hilton Niagara Falls/Fallsview Hotel &amp; Suites</h4><p style="margin:10px 0 0 0; line-height: 2em">6361 Fallsview Blvd Niagara Falls, Ontario L2G 3V9<br />905-354-7887<br /><a href="https://www.google.ca/maps/dir//6361 Fallsview Blvd Niagara Falls, Ontario L2G 3V9" target="_blank">Directions</a></p>',
            minWidth: 280,
            minHeight: 200,
            borderRadius: 0,
            arrowSize: 10,
            padding: 20,
            borderWidth: 0,
            borderColor: '#D7D7D7',
            shadowStyle: 4,
            disableAutoPan: true,
            hideCloseButton: true,
        });
        info.open(map, marker);
        google.maps.event.addListener(marker, 'click', function() {
            if (!info.isOpen()) {
                info.open(map, marker);
            }
        });
        google.maps.event.addListener(map, "click", function() {
            info.close();
        });
    }